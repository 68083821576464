<template>
  <v-row>
    <v-col v-if="metodoPago.solicitudPasarelaPago" class="mt-3"  cols="12">
      <v-alert color="primary" outlined :value="true" text class="body-2" v-if="['CREADO','SOLICITADO','EN_PROCESO'].includes(metodoPago.solicitudPasarelaPago.estado)">
        Usted tiene pendiente una solicitud de pago mediante la <b>PASARELA DE PAGOS</b>, para continuar debe 
        <span v-if="metodoPago.solicitudPasarelaPago.metodoPago"> realizar el pago mediante <b>{{metodoPago.solicitudPasarelaPago.metodoPago}}</b> que se muestra a continuacion:</span>
        <span v-else><v-btn color="primary" rounded small @click="getUrlRedireccion(metodoPago.solicitudPasarelaPago.id)">presionar aqui</v-btn> para realizar el pago (código transaccion: {{metodoPago.solicitudPasarelaPago.codigoTransaccion}})</span>
      </v-alert>
    </v-col>
    <v-col cols="12" v-if="metodoPago.qr!==null">
       <qr-pago 
          v-if="metodoPago.qr!== null" 
          :qr="metodoPago.qr" 
          :center="true"
        />
    </v-col>
    <v-col cols="12" v-if="metodoPago.cpt!==null" >
     <codigo-pago
          :cpt="metodoPago.cpt" 
          :center="true"
        />
    </v-col>
    <v-col cols="12" v-if="sociedadPendienteFirma">
      <v-alert color="orange" outlined :value="true" text class="body-2">
        Un operador, realizo cambios a tu solicitud de registro de sociedad civil por lo tanto debes volver a firmar tu formulario, para continuar con tu tramite
        <v-btn :loading="loading" color="success" rounded small @click="firmarRegistro(sociedadPendienteFirma)">presiona aqui</v-btn>
      </v-alert>
    </v-col>
    <v-col cols="12" class="mt-3">
      <v-btn
        class="mb-1" 
        color="primary lighten-3"
        small 
        rounded
        @click="dialogNuevo=true"
      >
        <v-icon class="mr-2">mdi-card-plus-outline</v-icon>Registrar Nueva Sociedad
      </v-btn>
      <v-btn @click="getSociedadesCiviles" class="ml-2" color="grey" rounded dark small>
        <v-icon >mdi-refresh</v-icon>
      </v-btn>
      <NuevaSociedad 
        v-if="dialogNuevo" 
        :dialog="dialogNuevo"
        :value="selected"
        @cancelar="cerrar"
        @ok="nuevaSolicitudRegistradaExitosamente"
      />
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="desserts"
        item-key="id"
        :server-items-length="totalDesserts"
        :hide-default-footer="hiddenFoter"
        no-data-text="No esta registrado en ninguna Sociedad Civil"
        :mobile-breakpoint="100"
        :loading="loadingTable"
        calculate-widths
      >
        <template
            v-slot:item.acciones="{item}"
        >
          <v-tooltip bottom  >
            <template v-slot:activator="{ on, attrs }">
            <v-btn 
              text 
              color="secondary"
              icon
              @click="getInformacion(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
              mdi-more
              </v-icon>
            </v-btn>
            </template>
            <span>Ver Informacion</span>
          </v-tooltip>

          <v-tooltip bottom  v-if="item.estado=='CONCLUIDO' && item.activo && item.esRepresentante" >
            <template v-slot:activator="{ on, attrs }">
            <v-btn 
              text 
              color="orange"
              icon
              @click="modificacion(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
              mdi-pencil-circle-outline
              </v-icon>
            </v-btn>
            </template>
            <span>Solicitar Modificación</span>
          </v-tooltip>
        </template>
        <template v-slot:item.estado="{item}">
          <v-chip :color="getColorEstado(item.estado)" small>
            {{item.estado}}
          </v-chip>
        </template>
        <template v-slot:item.fechaResolucion="{item}">
          {{formatDateText(item.fechaResolucion)}}
        </template>
      </v-data-table>
      <InformacionSociedad
        v-if="dialog"
        :id="selected.idSociedadCivil"
        :dialog="dialog"
        @cerrar="cerrar"
      />
    </v-col>
  </v-row>
</template>
<script>
import Mabogado from "@/mixins/abogado";
import mixinDateTime from "@/mixins/datetime"
export default {
  mixins: [Mabogado,mixinDateTime],
  components:{
    'codigo-pago':()=>import("@/components/Cpt"),
    'qr-pago':()=>import("@/components/QrPago"),
    NuevaSociedad:()=>import("@/components/NuevaSociedad"),
    InformacionSociedad:()=>import("@/components/InfoSociedadCivil")
  },
  data() {
    return {
      headers: [
        {
          text: "Acciones",
          align: "left",
          value: "acciones",
          sortable: false
        },
        {
          text: "Estado",
          align: "left",
          value: "estado",
          sortable: false
        },
        {
          text: "Nombre Sociedad",
          align: "left",
          value: "nombreSociedad",
          sortable: false
        },
        {
          text: "Matricula",
          align: "left",
          value: "matricula",
          sortable: false
        },
        {
          text: "Numero Resolucion",
          align: "left",
          value: "numeroResolucion",
          sortable: false
        },
        {
          text: "Fecha Resolucion",
          align: "left",
          value: "fechaResolucion",
          sortable: false
        },
        {
          text: "Cargo",
          align: "left",
          value: "cargo",
          sortable: false
        }
      ],
      loadingTable:true,
      desserts:[],
      hiddenFoter: true,
      metodoPago:{
        solicitudPasarelaPago:null,
        cpt:null,
        qr:null
      },
      dialog:false,
      dialogNuevo:false,
      selected:{}
    };
  },
  async created(){
    await this.getSociedadesCiviles();
  },
  computed: {
    totalDesserts() {
      if (this.desserts.length > 8) {
        this.hiddenFoter = false;
      }
      return this.desserts.length;
    },
    sociedadPendienteFirma(){
      return this.desserts.find(x=>x.estado==='PENDIENTE FIRMA')
    }    
  },
  methods:{
    getInformacion(sociedad){
      this.selected={...sociedad}
      this.dialog=true       
    },
    getCPT(sociedad){
       this.$http.get(`${this.$apiUrl}abogado-ciudadano/sociedades-civiles/${sociedad.idSociedadCivil}/codigo-pago-tramite`)
       .then(response=>{
               this.metodoPago.cpt = response.data.data       
        }).catch(error=>{
        console.log('------------------------------------');
        console.log(error);
        console.log('------------------------------------');
        })
    },
    firmarRegistro(sociedad){
      this.loading=true
        this.$http.put(`${this.$apiUrl}abogado-ciudadano/sociedades-civiles/${sociedad.idSociedadCivil}/solicitar-firma-registro`).then(response=>{
              this.loading=false
              if(response.data.data){
                if(response.data.data.aprobacionCiudadania.linkAprobacion) {
              this.$notify({
                    group:'foo',
                    type:'success',
                    title:'APROBACION DOCUMENTOS',
                    text:'Debe aprobar los documentos de su solicitud con Ciudadanía Digital'
                })
               window.location.href = response.data.data.aprobacionCiudadania.linkAprobacion
            }
              }
          }).catch(error=>{
            console.log(error)
            this.loading=false
        })
    },
    getSolicitudPasarelaPago(sociedad){
      this.$http.get(`${this.$apiUrl}abogado-ciudadano/sociedades-civiles/${sociedad.idSociedadCivil}/metodo-pago`)
       .then(response=>{
            const {data} = response.data
               this.metodoPago.solicitudPasarelaPago = data.solicitudPasarelaPago || null
               this.metodoPago.qr = data.qr || null
               this.metodoPago.cpt = data.cpt || null
        }).catch(error=>{
        console.log('------------------------------------');
        console.log(error);
        console.log('------------------------------------');
        })
    },
    nuevaSolicitudRegistradaExitosamente(){
      this.getSociedadesCiviles()
      this.dialogNuevo=false
    },
     getColorEstado(estado){
      const colores={
        'CONCLUIDO':'success',
        'PENDIENTE FIRMA':'warning',
        'NUEVO':'error',
        'PAGADO':'warning'
      }
      return colores[estado]
    },
    cerrar(){
      this.selected = {}
      this.dialog = false
      this.dialogNuevo = false
    },
    modificacion(item){
      this.selected = {...item}
      this.dialogNuevo=true
    },
    getUrlRedireccion(id){
       this.$http.get(`${this.$apiUrl}abogado-ciudadano/solicitud-pasarela-pago/${id}/url-redireccion`).then(response=>{
        const {data, error} = response.data
        if(data){
          if(!error && data.estado === 'SOLICITADO' ){
            if(data.codigoTransaccion){
              this.$storage.set('codigo_transaccion',data.codigoTransaccion)
            }
            this.goUrl(data.urlRedireccion)
            return
          }
          if(!error && data.estado === 'EN_PROCESO' ){
            if(data.qr){
              this.metodoPago.qr = null
              const e = data.qr
              this.metodoPago.qr = {
                  id: e.id, 
                  idQr: e.id_qr,
                  codigoQr: e.codigo_qr||null,
                  monto: e.monto,
                  estado: e.estado_codigo.nombre,
                  tipoTramite: "CREDENCIAL",
                  fechaCreacion: e.fecha_creacion,
                  activo: true
              }
            }else{
              this.metodoPago.cpt = null
               const e = data.cpt
              this.metodoPago.cpt = {
                  id: e.id, 
                  codigo: e.codigo_cpt,
                  fechaInicio: e.fecha_inicio,
                  fechaVencimiento: e.fecha_vencimiento,
                  estado: e.estado_codigo.nombre,
                  monto: e.monto
              }
            }
          }
        }
       })
    },
    goUrl(url){
      window.location.href = url
    }
  }
};
</script>
